import React from "react"
import {catchError, flatMap, map, mergeMap, switchMap, tap} from "rxjs/operators"
import {forkJoin, of, throwError} from "rxjs"
import {EAddressType, EShippingType, IUserContext} from "../common/common.models"
import {IUserData} from "../common/user.models"
import {EAddAddressId, ESetAddress} from "../components/checkout/checkout.models"
import {LabelContext} from "./label.context"
import {AlertContext} from "./alert.context"
import {calls, PROJECT_KEY, BRX_BASE_URL, PROJECT_LANGUAGE, AUTH_PASSWORD, PROJECT_COUNTRY} from "./APIUtils"
import {BannerContext} from "./banner.context"
import {RESTContext} from "./rest.context"
import {GeneralContext} from "./general.context"
import {CartContext} from "./cart.context"
import {ICart} from "../common/cart.models"
import {getBrowserInfo} from "../common/constants"
import {Observable} from "rxjs/internal/Observable"

export const UserContext = React.createContext<IUserContext>(null!)

export const UserContextComponent = (props: any) => {
    const [messageActivationMail] = React.useState("")
    const alertConsumer = React.useContext(AlertContext)
    const bannerConsumer = React.useContext(BannerContext)
    const {token, setToken, ajax$, ajaxMiddleware$, AWSlogger} = React.useContext(RESTContext)
    const {getOrders, getActiveCart, setAddress} = React.useContext(CartContext)
    const {
        userData,
        setUserData,
        forgotPasswordSent,
        setForgotPasswordSent,
        giftcardActivated,
        setGiftcardActivated,
        resetPasswordState,
        setResetPasswordState,
        cart,
        setCart,
    } = React.useContext(GeneralContext)
    const {t} = React.useContext(LabelContext)

    const verifyCostumerEmail = (token: string) => {
        const body = {
            tokenValue: token,
        }

        ajax$(calls.Project.verifyMyCostumerEmail(), undefined, body).subscribe(
            (res) => {
                setUserData(res)
            }, (err) => {}
        )
    }

    const changePwdCustomer = (data: any) => {
        const body = {
            version: userData.version,
            currentPassword: data.oldpassword,
            newPassword: data.newpassword,
        }

        ajax$(calls.Project.changePwdCustomer(), undefined, body).subscribe(
            (res) => {
                ajax$(calls.Authorization.getAnonymousToken(), {
                    access_token: null,
                    token_type: null,
                }).subscribe((res_: any) =>
                    login(userData.email, data.newpassword, res_, () => {
                        bannerConsumer.show({
                            type: t("ecommerce.myaccount.pwd-modified"),
                        })
                    })
                )
            },
            (err) => {
                bannerConsumer.show({
                    type: t("ecommerce.myaccount.change-pwd-original-pwd-wrong"),
                })
            }
        )
    }

    const updateCustomer = (data: any) => {
        const body: any = {
            version: userData.version,
            actions: [
                {
                    action: "setFirstName",
                    firstName: data.name,
                },
                {
                    action: "setLastName",
                    lastName: data.surname,
                },
                {
                    action: "setSalutation",
                    salutation: data.sex,
                }
            ],
        }

        if(data && data.birthday){
            body.actions.push({
                action: "setDateOfBirth",
                dateOfBirth:
                    data.birthday.getFullYear() +
                    "-" +
                    (parseInt(data.birthday.getMonth()) + 1) +
                    "-" +
                    data.birthday.getDate()
            })
        }else {
            body.actions.push({
                action: "setDateOfBirth",
                dateOfBirth: null
            })
        }

        if (data && data.profiling !== undefined && body && body.actions) {
            let act = {
                action: "setCustomField",
                name: "consentProfiling",
                value: data.profiling
            }
            body.actions.push(act)
        }

        ajax$(calls.Project.updateCustomer(), undefined, body).subscribe((res) => {
            setUserData(res)
        })
    }

    const signup = (data: any) => {
        const body_: any = {
            locale: PROJECT_LANGUAGE,
            email: data.email,
            password: data.password,
            firstName: data.name,
            lastName: data.surname,
            salutation: data.sex,
            anonymousCart: {
                id: cart.id,
                typeId: "cart",
            },
            custom: {
                type: {
                    typeId: "type",
                    key: "newsletter-preferences",
                },
                fields: {
                    subscribed: data.newsletter,
                    consentProfiling: data.profiling
                }
            }
        }

        if(data.birthday){
            body_["dateOfBirth"] = data.birthday?.getFullYear() + "-" + (parseInt(data.birthday.getMonth()) + 1) + "-" + data.birthday.getDate()
        }

        ajax$(calls.Project.signup(), undefined, body_)
            .subscribe(
                res => {


                    let body_verify = {
                        "ttlMinutes": 720,
                        "id": res.customer.id,
                        "projectKey": PROJECT_KEY,
                        "baseUrl": BRX_BASE_URL
                    }

                    dataLayer.push({
                        event: 'submit-form-registrazione-ok'
                    })

                    if(window.connectif?.managed?.isInitialized()){
                        window.connectif.managed.sendEvents([
                            {type: 'register'}
                        ], { entityInfo: {
                            primaryKey: data.email || '',
                            _name: data.name || '',
                            _surname: data.surname || '',
                            isregistered: true
                        } });
                    } 

                    ajax$(calls.Authorization.getAnonymousToken(), {
                        access_token: null,
                        token_type: null,
                    }).subscribe((res: any) => {
                        login(data.email, data.password, res, null, false)
                    })

                    // Non occorre aspettare la risposta da BRX per invio mail
                    ajax$(calls.Project.sendVerifyMail(), undefined, body_verify).subscribe()
                },
                (err) => {
                    alertConsumer.show({type: err.response})
                }
            )
    }

    const loginAsGuestFromCheckout = (email: string,
                                      shippingAddress: any,
                                      billingAddress: any,
                                      useShippingAddressAsBilling: boolean,
                                      shippingType: EShippingType,
                                      skipAddress: boolean): Observable<any> => {
        const body = {
            "version": cart.version,
            "actions": [] as any
        }

        body.actions.push({
            action: "setCustomerEmail",
            email: email
        })

        body.actions.push({
            action: "setLocale",
            locale: PROJECT_LANGUAGE
        })

        body.actions.push({
            "action": "recalculate",
            "updateProductData": true
        })

        if(!skipAddress){
            if(shippingType === EShippingType.Pickup){
                body.actions.push({
                    "action": "setBillingAddress",
                    "address": billingAddress
                });
            } else {
                body.actions.push({
                    "action": "setShippingAddress",
                    "address": shippingAddress
                })

                body["actions"].push({
                        "action": "setShippingMethod",
                    }
                )

                if (useShippingAddressAsBilling) {
                    body.actions.push({
                        "action": "setBillingAddress",
                        "address": shippingAddress
                    })
                } else {
                    body.actions.push({
                        "action": "setBillingAddress",
                        "address": billingAddress
                    });
                }
            }
        }

        return ajax$(calls.Project.updateCart(cart.id), undefined, body).pipe(
            map(res_cart => {
                setCart(res_cart);
                return res_cart;
            }),
            catchError(err => {
                alertConsumer.show({ type: err.response });
                return throwError(err);
            })
        );
    }

    //signup -> getAnonymousToken -> login -> getCustomerToken -> updateCustomer -> updateCustomer -> updateCart
    const signupFromCheckout = (
        email: string,
        password: string,
        newletterSubscribe: boolean = false,
        consentProfiling: boolean = false,
        shippingAddress: any,
        billingAddress: any,
        useShippingAddressAsBilling: boolean,
        shippingType: EShippingType
    ): Observable<any> => {
        const body_: any = {
            locale: PROJECT_LANGUAGE,
            email: email,
            password: password,
            anonymousCart: {
                id: cart.id,
                typeId: "cart",
            },
            custom: {
                type: {
                    typeId: "type",
                    key: "newsletter-preferences",
                },
                fields: {
                    subscribed: newletterSubscribe,
                    ...(["DE"].includes(PROJECT_COUNTRY) && {consentProfiling: consentProfiling})
                }
            }
        };

        return ajax$(calls.Project.signup(), undefined, body_).pipe(
            mergeMap(res => {
                let body_verify = {
                    "ttlMinutes": 720,
                    "id": res.customer.id,
                    "projectKey": PROJECT_KEY,
                    "baseUrl": BRX_BASE_URL
                };

                dataLayer.push({
                    event: 'submit-form-registrazione-ok'
                });

                // Non occorre aspettare la risposta da BRX per invio mail
                ajax$(calls.Project.sendVerifyMail(), undefined, body_verify).subscribe();

                return ajax$(calls.Authorization.getAnonymousToken(), {
                    access_token: null,
                    token_type: null,
                }).pipe(
                    mergeMap((ptoken: any) => {
                        AWSlogger({
                            "type": "log",
                            "action": "BeforeLogin",
                            "email": email,
                            "browserInfo": getBrowserInfo(),
                            "cartId": cart?.id,
                            "anonymousCartId": cart?.anonymousId
                        });

                        const body = {
                            email: email,
                            password,
                            anonymousId: cart.anonymousId,
                            anonymousCartId: cart.id,
                            anonymousCartSignInMode: "MergeWithExistingCustomerCart",
                        };

                        return ajax$(calls.Project.login(), ptoken ? ptoken : token, body).pipe(
                            mergeMap(res_login => {
                                AWSlogger({
                                    "type": "log",
                                    "action": "AfterLogin",
                                    "email": email,
                                    "browserInfo": getBrowserInfo(),
                                    "cartId": res_login?.cart?.id
                                })

                                email = encodeURIComponent(email)
                                password = encodeURIComponent(password)

                                return ajax$(calls.Authorization.getCustomerToken({ email: email, password: password }), null).pipe(
                                    mergeMap(res_token => {
                                        setToken(res_token)
                                        setUserData(res_login.customer)

                                        const body = {
                                            version: res_login.customer.version,
                                            actions: [] as any
                                        }

                                        if(shippingType === EShippingType.Pickup){
                                            body.actions.push(
                                                {
                                                    action: billingAddress.id ? "changeAddress" : "addAddress",
                                                    addressId: billingAddress?.id,
                                                    address: billingAddress,
                                                },
                                            )
                                        }else{
                                            if (useShippingAddressAsBilling) {
                                                body.actions.push(
                                                    {
                                                        action: shippingAddress.id ? "changeAddress" : "addAddress",
                                                        addressId: shippingAddress?.id,
                                                        address: shippingAddress,
                                                    },
                                                    {
                                                        action: shippingAddress.id ? "changeAddress" : "addAddress",
                                                        addressId: shippingAddress?.id,
                                                        address: shippingAddress,
                                                    }
                                                )
                                            } else {
                                                body.actions.push(
                                                    {
                                                        action: shippingAddress.id ? "changeAddress" : "addAddress",
                                                        addressId: shippingAddress?.id,
                                                        address: shippingAddress,
                                                    },
                                                    {
                                                        action: billingAddress.id ? "changeAddress" : "addAddress",
                                                        addressId: billingAddress?.id,
                                                        address: billingAddress,
                                                    }
                                                )
                                            }

                                        }


                                        return ajax$(calls.Project.updateCustomer(), res_token, body).pipe(
                                            mergeMap(customer => {
                                                let _body: any

                                                if(shippingType === EShippingType.Pickup){
                                                    _body = {
                                                        version: customer.version,
                                                        actions: [
                                                            {
                                                                action: "addBillingAddressId",
                                                                addressId: billingAddress.id ? billingAddress.id : customer.addresses[customer.addresses.length - 1].id,
                                                            }
                                                        ],
                                                    }
                                                } else {
                                                    if (useShippingAddressAsBilling) {
                                                        _body = {
                                                            version: customer.version,
                                                            actions: [
                                                                {
                                                                    action: "addShippingAddressId",
                                                                    addressId: shippingAddress.id ? shippingAddress.id : customer.addresses[customer.addresses.length - 2].id,
                                                                },
                                                                {
                                                                    action: "addBillingAddressId",
                                                                    addressId: shippingAddress.id ? shippingAddress.id : customer.addresses[customer.addresses.length - 1].id,
                                                                }
                                                            ],
                                                        }
                                                    } else {
                                                        _body = {
                                                            version: customer.version,
                                                            actions: [
                                                                {
                                                                    action: "addShippingAddressId",
                                                                    addressId: shippingAddress.id ? shippingAddress.id : customer.addresses[customer.addresses.length - 2].id,
                                                                },
                                                                {
                                                                    action: "addBillingAddressId",
                                                                    addressId: billingAddress.id ? billingAddress.id : customer.addresses[customer.addresses.length - 1].id,
                                                                }
                                                            ],
                                                        }
                                                    }
                                                }



                                                return ajax$(calls.Project.updateCustomer(), res_token, _body).pipe(
                                                    mergeMap(customer => {
                                                        setUserData(customer)

                                                        const body = {
                                                            "version": res_login.cart.version,
                                                            "actions": [] as any
                                                        }

                                                        body.actions.push({
                                                            "action": "recalculate",
                                                            "updateProductData": true
                                                        })

                                                        body.actions.push({
                                                            action: "setCustomerEmail",
                                                            email: res_login.customer.email
                                                        })

                                                        if(shippingType === EShippingType.Pickup){
                                                            body.actions.push({
                                                                "action": "setBillingAddress",
                                                                "address": { ...billingAddress, id: _body?.actions[0].addressId }
                                                            })
                                                        }else {
                                                            body.actions.push({
                                                                "action": "setShippingAddress",
                                                                "address": { ...shippingAddress, id: _body?.actions[0].addressId }
                                                            })

                                                            if (useShippingAddressAsBilling) {
                                                                body.actions.push({
                                                                    "action": "setBillingAddress",
                                                                    "address": { ...shippingAddress, id: _body?.actions[1].addressId }
                                                                })
                                                            } else {
                                                                body.actions.push({
                                                                    "action": "setBillingAddress",
                                                                    "address": { ...billingAddress, id: _body?.actions[1].addressId }
                                                                })
                                                            }
                                                        }

                                                        return ajax$(calls.Project.updateCart(res_login.cart.id), res_token, body).pipe(
                                                            map(res_cart => {
                                                                setCart(res_cart)
                                                                return res_cart
                                                            }),
                                                            catchError(err => {
                                                                if (err.response.statusCode !== 401) {
                                                                    alertConsumer.show({ type: err.response })
                                                                }
                                                                return throwError(err)
                                                            })
                                                        );
                                                    }),
                                                    catchError(err => {
                                                        alertConsumer.show({ type: err.response })
                                                        return throwError(err)
                                                    })
                                                );
                                            }),
                                            catchError(err => {
                                                alertConsumer.show({ type: err.response })
                                                return throwError(err)
                                            })
                                        );
                                    }),
                                    catchError(err => {
                                        if (err.response.statusCode !== 401) {
                                            alertConsumer.show({ type: err.response })
                                        }
                                        return throwError(err)
                                    })
                                );
                            }),
                            catchError(err => {
                                if (err.response.statusCode === 400) {
                                    alertConsumer.show({ type: err.response })
                                }
                                return throwError(err)
                            })
                        );
                    })
                );
            }),
            catchError(err => {
                alertConsumer.show({ type: err.response })
                return throwError(err)
            })
        );
    };

    //signup -> getAnonymousToken -> login -> getCustomerToken -> updateCustomer -> updateCustomer -> updateCart
    const signupFromKlarnaCheckout = (
        email: string,
        password: string,
        newletterSubscribe: boolean = false
    ): Observable<any> => {
        const body_: any = {
            locale: PROJECT_LANGUAGE,
            email: email,
            password: password,
            anonymousCart: {
                id: cart.id,
                typeId: "cart",
            },
            custom: {
                type: {
                    typeId: "type",
                    key: "newsletter-preferences",
                },
                fields: {
                    subscribed: newletterSubscribe
                }
            }
        };

        return ajax$(calls.Project.signup(), undefined, body_).pipe(
            mergeMap(res => {
                let body_verify = {
                    "ttlMinutes": 720,
                    "id": res.customer.id,
                    "projectKey": PROJECT_KEY,
                    "baseUrl": BRX_BASE_URL
                };

                dataLayer.push({
                    event: 'submit-form-registrazione-ok'
                });

                ajax$(calls.Project.sendVerifyMail(), undefined, body_verify).subscribe();

                return ajax$(calls.Authorization.getAnonymousToken(), {
                    access_token: null,
                    token_type: null,
                }).pipe(
                    mergeMap((ptoken: any) => {
                        AWSlogger({
                            "type": "log",
                            "action": "BeforeLogin",
                            "email": email,
                            "browserInfo": getBrowserInfo(),
                            "cartId": cart?.id,
                            "anonymousCartId": cart?.anonymousId
                        });

                        const body = {
                            email: email,
                            password,
                            anonymousId: cart.anonymousId,
                            anonymousCartId: cart.id,
                            anonymousCartSignInMode: "MergeWithExistingCustomerCart",
                        };

                        return ajax$(calls.Project.login(), ptoken ? ptoken : token, body).pipe(
                            mergeMap(res_login => {
                                AWSlogger({
                                    "type": "log",
                                    "action": "AfterLogin",
                                    "email": email,
                                    "browserInfo": getBrowserInfo(),
                                    "cartId": res_login?.cart?.id
                                })

                                email = encodeURIComponent(email)
                                password = encodeURIComponent(password)

                                return ajax$(calls.Authorization.getCustomerToken({ email: email, password: password }), null).pipe(
                                    mergeMap(res_token => {
                                        setToken(res_token)
                                        setUserData(res_login.customer)

                                        const body = {
                                            "version": res_login.cart.version,
                                            "actions": [] as any
                                        }

                                        body.actions.push({
                                            "action": "recalculate",
                                            "updateProductData": true
                                        })

                                        body.actions.push({
                                            action: "setCustomerEmail",
                                            email: res_login.customer.email
                                        })

                                        return ajax$(calls.Project.updateCart(res_login.cart.id), res_token, body).pipe(
                                            map(res_cart => {
                                                setCart(res_cart)
                                                return res_cart
                                            }),
                                            catchError(err => {
                                                if (err.response.statusCode !== 401) {
                                                    alertConsumer.show({ type: err.response })
                                                }
                                                return throwError(err)
                                            })
                                        );
                                    }),
                                    catchError(err => {
                                        if (err.response.statusCode !== 401) {
                                            alertConsumer.show({ type: err.response })
                                        }
                                        return throwError(err)
                                    })
                                );
                            }),
                            catchError(err => {
                                if (err.response.statusCode === 400) {
                                    alertConsumer.show({ type: err.response })
                                }
                                return throwError(err)
                            })
                        );
                    })
                );
            }),
            catchError(err => {
                alertConsumer.show({ type: err.response })
                return throwError(err)
            })
        );
    };


    const setCustomerEmail = (email: string) => {
        AWSlogger({
            "type": "log",
            "action": "setCustomerEmail",
            "email": email,
            "browserInfo": getBrowserInfo()
        })
        const body = {
            version: cart.version,
            actions: [
                {
                    action: "setCustomerEmail",
                    email: email,
                },
                {
                    action: "setLocale",
                    locale: PROJECT_LANGUAGE
                }
            ],
        }

        ajax$(calls.Project.updateCart(cart.id), undefined, body).subscribe((res) => setCart(res))

        if(window.connectif?.managed?.isInitialized()){
            window.connectif.managed.sendEvents([
                {type: 'page-visit'}
            ], { entityInfo: {
                primaryKey: email || ''
            } });
        }
    }

    const login = (username: string, password: string, pToken?: any, callback?: any, isLogin = true) => {
        AWSlogger({
            "type": "log",
            "action": "BeforeLogin",
            "email": username,
            "browserInfo": getBrowserInfo(),
            "cartId": cart?.id,
            "anonymousCartId": cart?.anonymousId
        })
        const body = {
            email: username,
            password,
            anonymousId: cart.anonymousId,
            anonymousCartId: cart.id,
            anonymousCartSignInMode: "MergeWithExistingCustomerCart",
        }
        ajax$(calls.Project.login(), pToken ? pToken : token, body)
            .subscribe(res_login => {
                    AWSlogger({
                        "type": "log",
                        "action": "AfterLogin",
                        "email": username,
                        "browserInfo": getBrowserInfo(),
                        "cartId": res_login?.cart?.id
                    })

                    if (isLogin) {
                        dataLayer.push({
                            'event': 'submit-form-login-ok'
                        });
                    }

                    if(window.connectif?.managed?.isInitialized()){
                        window.connectif.managed.sendEvents([
                            {type: 'login'}
                        ], { entityInfo: {
                            primaryKey: res_login?.customer?.email || '',
                            _name: res_login?.customer?.firstName || '',
                            _surname: res_login?.customer?.lastName || '',
                            isregistered: true
                        } });
                    } 

                    username = encodeURIComponent(username);
                    password = encodeURIComponent(password);

                    ajax$(calls.Authorization.getCustomerToken({email: username, password: password}), null)
                        .subscribe(
                            res_token => {
                                setToken(res_token);
                                setUserData(res_login.customer);
                                //setCart(res_login.cart);

                                // Setto customerEmail nel cart
                                const body = {
                                    version: res_login.cart.version,
                                    actions: [
                                        {
                                            action: "setCustomerEmail",
                                            email: res_login.customer.email
                                        }
                                    ]

                                }
                                ajax$(calls.Project.updateCart(res_login.cart.id), res_token, body).subscribe(
                                    res_cart => {
                                        setCart(res_cart);
                                        if (callback)
                                            callback()
                                    }
                                    ,
                                    // type?.errors[0]?.code
                                    err => {
                                        if (err.response.statusCode !== 401) {
                                            alertConsumer.show({type: err.response})
                                        }
                                    }
                                )
                            },
                            (err) => {
                                if (err.response.statusCode !== 401) {
                                    alertConsumer.show({type: err.response})
                                }
                            }
                        )

                    document.body.style.overflowY = "scroll";
                    const asideCart = document?.querySelector(".checkout-column-aside")
                    const preFooter = document?.querySelector(".pre-footer");
                    if(asideCart && asideCart instanceof HTMLElement){
                    asideCart.style.zIndex = "0"
                    // asideCart.style.position = "sticky"
                    }
                    if(preFooter && preFooter instanceof HTMLElement){
                    // preFooter.style.position = "relative"
                    preFooter.style.zIndex = "0"
                    }
                },
                (err) => {
                    // Credenziali
                    if (err.response.statusCode === 400) {
                        alertConsumer.show({type: err.response})
                    }
                }
            )
    }

    const logout = () => {
        setCart(null!)
        setUserData(null!)
        setToken(null!)
        AWSlogger({
            "type": "log",
            "action": "Logout",
            "browserInfo": getBrowserInfo(),
            "email": cart?.customerEmail,
            "cartId": cart?.id,
            "anonymousCartId": cart?.anonymousId
        })
    }

    const forgotPassword = (email: string) => {
        const body = {
            email: email,
            ttlMinutes: 720,
            projectkey: PROJECT_KEY,
            baseUrl: BRX_BASE_URL,
        }

        ajax$(calls.Project.forgotPassword(), undefined, body)
            .pipe(
                tap(
                    (res) => {
                        //TBD non è ancora pronta la risposta di successo
                        setForgotPasswordSent(true)
                    },
                    (err) => {
                        AWSlogger({
                            "type": "error",
                            "action": "forgotPassword",
                            "response": err,
                            "showPopup": "yes",
                            "browserInfo": getBrowserInfo()
                        })
                        if (err.status === 404) {
                            alertConsumer.show({
                                type: {errors: [{code: "email-not-found-in-recover-pwd"}]},
                            })
                        } else {
                            alertConsumer.show({type: err.response})
                        }
                    }
                )
            )
            .subscribe()
    }

    const activateGiftcard = (serial: string, cvc: string) => {
        const body = {
            serialNumber: serial,
            cvc: cvc,
            projectKey: PROJECT_KEY,
            password: AUTH_PASSWORD,
            authToken: token?.access_token || ""
        }

        ajaxMiddleware$(calls.Project.activateGiftCard(), undefined, body)
            .subscribe(
                res => {
                    if(res?.code === 200){
                        setGiftcardActivated("YES")
                    }else {
                        setGiftcardActivated("ERROR")
                    }
                },
                err => {
                    AWSlogger({
                        "type": "error",
                        "action": "activateGiftcard",
                        "response": err,
                        "showPopup": "yes",
                        "browserInfo": getBrowserInfo()
                    })
                    setGiftcardActivated("ERROR")
                }
            )
    }

    const resetPassword = (token: string, newPassword: string) => {
        const body = {
            token: token || null,
            newPassword: newPassword,
            projectkey: PROJECT_KEY,
            baseUrl: BRX_BASE_URL,
        }

        ajax$(calls.Project.resetPassword(), undefined, body)
            .pipe(
                tap(
                    (res) => {
                        //TBD non è ancora pronta la risposta di successo
                        setResetPasswordState(1)
                    },
                    (err) => {
                        AWSlogger({
                            "type": "error",
                            "action": "resetPassword",
                            "response": err,
                            "showPopup": "yes",
                            "browserInfo": getBrowserInfo()
                        })
                        alertConsumer.show({type: err.response})
                    }
                )
            )
            .subscribe()
    }

    const getUserData = () => {
        ajax$(calls.Project.getCustomerDetails())
            .pipe(
                tap(
                    (res) => {
                        setUserData(res)
                        getActiveCart()
                        getOrders()
                        //getWishlist();
                    },
                    (err) => {
                        getActiveCart()
                    }
                )
            )
            .subscribe()
    }

    const removeAddress = (address: any) => {
        let body = {
            version: userData.version,
            actions: [
                {
                    action: "removeAddress",
                    addressId: address.id,
                },
            ],
        }

        ajax$(calls.Project.updateCustomer(), undefined, body).subscribe((res) => {
            setUserData(res)
            getActiveCart()
        })
    }

    const addAddress = (address: any, type: EAddressType, userData_: IUserData = userData, cart_: ICart = cart) => {
        const body = {
            version: userData_.version,
            actions: [
                {
                    action: address.id ? "changeAddress" : "addAddress",
                    addressId: address?.id,
                    address: address,
                },
            ],
        }
        return ajax$(calls.Project.updateCustomer(), undefined, body).pipe(
            flatMap((res) => {
                const body_ = {
                    version: res.version,
                    actions: [
                        {
                            action: EAddAddressId[type],
                            addressId: address.id ? address.id : res.addresses[res.addresses.length - 1].id,
                        },
                    ],
                }
                return ajax$(calls.Project.updateCustomer(), undefined, body_).pipe(
                    flatMap((res_) => {
                        setUserData(res_)
                        return forkJoin(of(res_), setAddress({...address, id: body_.actions[0].addressId}, type, cart_))
                    })
                )
            })
        )
    }

    //dato un array di indirizzi li collega al customer di CT e poi li aggiunge a carrello
    //non aggiunge l'indirizzo su CT se shippingType = Pickup
    const addMultipleAddress = (addressTypePairs: { address: any; type: EAddressType, initialIndex?: any }[], updateCart: boolean, shippingType: EShippingType, userData_: IUserData = userData, cart_: ICart = cart) : Observable<any> => {
        const body: any = {
            version: userData_.version,
            actions: []
        }

        // Mappa per tenere traccia degli ID degli indirizzi aggiornati
        const addressIdMap = new Map<EAddressType, string>();

        addressTypePairs.forEach(({ address, type }, index) => {
            if (!(shippingType === EShippingType.Pickup && type === EAddressType.Shipping)) {
                body.actions.push({
                    action: address.id ? "changeAddress" : "addAddress",
                    addressId: address?.id,
                    address: address,
                })
                address.initialIndex = index
            }
        })

        // Primo aggiornamento dell'utente
        return ajax$(calls.Project.updateCustomer(), undefined, body).pipe(
            switchMap((res) => {
                const body_: any = {
                    version: res.version,
                    actions: []
                }

                const n = addressTypePairs.filter(({ type }) => !(shippingType === EShippingType.Pickup && type === EAddressType.Shipping)).length;
                const lastNAddresses = res.addresses.slice(-n);

                // Ordina lastNAddresses in base all'ordine originale degli addressTypePairs
                const sortedAddresses = lastNAddresses.sort((a: any, b: any) => {
                    const indexA = addressTypePairs.findIndex(pair => pair.address.id === a.id);
                    const indexB = addressTypePairs.findIndex(pair => pair.address.id === b.id);
                    return indexA - indexB;
                });


                let validIndex = 0; // Contatore per gli indirizzi validi

                addressTypePairs.forEach(({ address, type }) => {
                    if (!(shippingType === EShippingType.Pickup && type === EAddressType.Shipping)) {
                        const addressId = address.id ? address.id : sortedAddresses[validIndex].id;
                        addressIdMap.set(type, addressId);

                        body_.actions.push({
                            action: EAddAddressId[type],
                            addressId: addressId
                        });

                        validIndex++; // Incrementa solo se l'indirizzo è stato effettivamente processato
                    }
                })

                // Secondo aggiornamento dell'utente
                return ajax$(calls.Project.updateCustomer(), undefined, body_).pipe(
                    switchMap((res_) => {
                        setUserData(res_)

                        if(updateCart){
                            const body = {
                                "version": cart_.version,
                                "actions": [] as any
                            }

                            body["actions"].push({
                                "action": "recalculate",
                                "updateProductData": true
                            })

                            addressTypePairs.forEach(({ address, type }, index) => {
                                if (!(shippingType === EShippingType.Pickup && type === EAddressType.Shipping)) {
                                    const addressId = addressIdMap.get(type)
                                    body.actions.push({
                                        "action": ESetAddress[type],
                                        "address": { ...address, id: addressId }
                                    })

                                    if (type === EAddressType.Shipping) {
                                        body["actions"].push({
                                                "action": "setShippingMethod",
                                            }
                                        )
                                    }
                                }
                            })

                            return ajax$(calls.Project.updateCart(cart_.id), undefined, body).pipe(
                                map((res) => {
                                    setCart(res)
                                    return res
                                })
                            )
                        } else {
                            return of(res_)
                        }
                    })
                )
            })
        )
    }

    return (
        <UserContext.Provider
            value={{
                signup,
                logout,
                login,
                forgotPassword,
                resetPassword,
                verifyCostumerEmail,
                getUserData,
                addAddress,
                removeAddress,
                updateCustomer,
                changePwdCustomer,
                forgotPasswordSent,
                resetPasswordState,
                setCustomerEmail,
                messageActivationMail,
                activateGiftcard,
                giftcardActivated,
                setGiftcardActivated,
                signupFromCheckout,
                signupFromKlarnaCheckout,
                loginAsGuestFromCheckout,
                addMultipleAddress
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}
